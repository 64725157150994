import React from 'react';
import GetInTouch from "./getInTouch.jsx";


function App() {
    return (
        <div>
            <GetInTouch/>

        </div>
    )

}

export default App;
