import React, {Fragment} from 'react';
import {Popover, Transition} from '@headlessui/react'
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline'
import {ArrowTopRightOnSquareIcon} from '@heroicons/react/20/solid'
import FtLogo from './assets/android-chrome-192x192.png'

const navigation = [{name: 'Work', href: '#'}, {name: 'Services', href: '#'}, {
    name: 'About', href: '#'
}, {
    name: 'Blog', href: '#'
},]

export default function GetInTouch() {

    return (<div className="bg-white">
        <Popover as="header" className="relative z-10">
            <div className="bg-gray-50">
                <nav
                    className="relative mx-auto flex max-w-7xl items-center justify-between px-6 pt-8 xl:px-8"
                    aria-label="Global"
                >
                    <div className="flex w-full items-center justify-between lg:w-auto">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="#">
                            <span className="sr-only">FelonTech</span>
                            <img
                                className="rounded-lg h-10 w-auto sm:h-10"
                                src={FtLogo}
                                alt="alt"
                            />
                        </a>
                        <div className="-mr-2 flex items-center lg:hidden">
                            <Popover.Button
                                className="focus-ring-inset inline-flex items-center justify-center rounded-md bg-gray-50 p-2 text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-slate-500">
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                            </Popover.Button>
                        </div>
                    </div>
                    <div className="hidden space-x-10 lg:ml-10 lg:flex">
                        {navigation.map((item) => (<a key={item.name} href={item.href}
                                                      className="text-base font-medium text-gray-500 hover:text-gray-600">
                            {item.name}
                        </a>))}
                    </div>
                    <div className="hidden lg:flex lg:items-center lg:space-x-6">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                            href='#'
                            className="rounded-md border border-transparent bg-white py-2 px-6 text-base font-medium text-slate-500 shadow-md hover:bg-gray-50"
                        >
                            Contact
                        </a>
                    </div>
                </nav>
            </div>

            <Transition
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel focus
                               className="absolute inset-x-0 top-0 origin-top transform p-2 transition lg:hidden">
                    <div className="overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="flex items-center justify-between px-5 pt-4">
                            <div>
                                <img
                                    className="h-8 w-auto"
                                    src="#"
                                    alt=""
                                />
                            </div>
                            <div className="-mr-2">
                                <Popover.Button
                                    className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-500">
                                    <span className="sr-only">Close menu</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                </Popover.Button>
                            </div>
                        </div>
                        <div className="pt-5 pb-6">
                            <div className="space-y-1 px-2">
                                {navigation.map((item) => (<a
                                    key={item.name}
                                    href={item.href}
                                    className="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50"
                                >
                                    {item.name}
                                </a>))}
                            </div>
                            <div className="mt-6 px-5">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a
                                    href='#'
                                    className="block w-full rounded-md border border-transparent bg-slate-500 py-2 px-4 text-center font-medium text-white shadow hover:bg-slate-600"
                                >
                                    Contact
                                </a>
                            </div>
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>

        <main>
            {/* Header */}
            <div className="bg-gray-50 py-24 sm:py-32">
                <div className="mx-auto max-w-md px-6 sm:max-w-lg lg:max-w-7xl lg:px-8">
                    <h1 className="text-center text-4xl font-bold leading-10 tracking-tight text-gray-900 sm:text-5xl sm:leading-none lg:text-6xl">
                        Empower Your Future
                    </h1>
                    <p className="mx-auto mt-6 max-w-3xl text-center text-xl leading-normal text-gray-500">
                        Learn valuable computer skills and jumpstart your career in the tech industry
                    </p>
                </div>
            </div>

            {/* Contact Section */}
            <div className="relative bg-white">
                <div className="lg:absolute lg:inset-0">
                    <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                        <img
                            className="rounded-lg h-80 w-80 mx-auto lg:absolute lg:inset-0 lg:h-96 lg:w-96  lg:mt-80 lg:ml-14 lg:max-w-none lg:mx-0"
                            src="https://i.imgur.com/qUq0yt6.png"
                            alt="alt"
                        />
                    </div>
                </div>
                <div
                    className="relative py-16 px-6 sm:py-24 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:px-8 lg:py-32">
                    <div className="lg:pr-8">
                        <div className="mx-auto max-w-md sm:max-w-lg lg:mx-0">
                            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">A second chance to
                                succeed</h2>
                            <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                                A computer program designed specifically for individuals
                                impacted by the justice system. Redefine your path.
                                Build a better future for yourself and your community.
                            </p>
                            <form action="#" method="POST"
                                  className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                                <div>
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                        First name
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="first-name"
                                            id="first-name"
                                            autoComplete="given-name"
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                        Last name
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="last-name"
                                            id="last-name"
                                            autoComplete="family-name"
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Email
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                                        State
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="state"
                                            id="state"
                                            autoComplete="state"
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <div className="flex justify-between">
                                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                                            Phone
                                        </label>
                                        <span id="phone-description" className="text-sm text-gray-500">
            Optional
            </span>
                                    </div>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="phone"
                                            id="phone"
                                            autoComplete="tel"
                                            aria-describedby="phone-description"
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <div className="flex justify-between">
                                        <label htmlFor="how-can-we-help"
                                               className="block text-sm font-medium text-gray-700">
                                            How can we help you?
                                        </label>
                                        <span id="how-can-we-help-description" className="text-sm text-gray-500">
            Max. 500 characters
            </span>
                                    </div>
                                    <div className="mt-1">
            <textarea
                id="how-can-we-help"
                name="how-can-we-help"
                aria-describedby="how-can-we-help-description"
                rows={4}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                defaultValue={''}
            />
                                    </div>
                                </div>

                                <div className="sm:col-span-2">
                                    <label htmlFor="how-did-you-hear-about-us"
                                           className="block text-sm font-medium text-gray-700">
                                        How did you hear about us?
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="how-did-you-hear-about-us"
                                            id="how-did-you-hear-about-us"
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                                        />
                                    </div>
                                </div>
                                <div className="text-right sm:col-span-2">
                                    <button
                                        type="submit"
                                        className="inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* CTA Section */}
            <div className="bg-slate-400">
                <div className="mx-auto max-w-md py-16 px-6 text-center sm:max-w-2xl sm:py-24 lg:px-8 lg:py-32">
                    <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
                        <span className="block text-white">Unlock your potential</span>
                        <span className="block text-slate-900"></span>
                    </h2>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                        href="#"
                        className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-white py-3 px-5 text-base font-medium text-slate-600 shadow-md hover:bg-slate-50 sm:w-auto"
                    >
                        <span>Learn more</span>
                        <ArrowTopRightOnSquareIcon className="ml-3 h-5 w-5 flex-shrink-0 text-gray-400"
                                                   aria-hidden="true"/>
                    </a>
                </div>
            </div>
        </main>

        <footer className="bg-slate-500" aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">
                Footer
            </h2>
            <div
                className="mx-auto mt-0 max-w-md py-12 px-6 sm:max-w-lg sm:pt-16 lg:max-w-7xl lg:px-8 lg:pt-24 lg:pb-16">
                <div className="border-t border-gray-700 pt-8">
                    <p className="text-center text-base text-gray-400 xl:text-center">
                        &copy; 2023 FelonTech, Inc. All rights reserved.
                    </p>
                </div>

            </div>
        </footer>
    </div>);
}



